import React from "react";
//-----COMPONENTS-----
import Layout from "../components/layout";
import PostPreview from "../components/blogPostPreview";
//-----MATERIAL-----
import Box from "@material-ui/core/Box";
import Posts from "../data/posts";

export default () => (
  <Layout>
    <Box height="100px" width={1} bgcolor="#000" />

    {Posts.map((post, index) => (
      <PostPreview post={post} key={index} />
    ))}
  </Layout>
);