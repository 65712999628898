//-----ASSETS-----
import PostImage from "../assets/post-image.png";

export default [
  {
    title: "where should i start with a remodal?",
    author: "Jake Terpstra",
    date: "Jan 01, 2020",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce ut placerat orci nulla…",
    body: [
      "Spicy jalapeno anim elit spare ribs nisi tongue minim lorem meatloaf shankle ut enim magna tail picanha. Meatloaf minim chislic, velit ribeye tempor irure flank frankfurter kevin proident. Pork fugiat drumstick cupidatat corned beef salami. Shoulder ham turkey, pork ribeye dolore alcatra ut landjaeger. Short loin swine irure pork belly ad andouille et rump burgdoggen tenderloin cow chuck consequat. Porchetta sunt ham hock do deserunt.",
      "Swine nulla pork chop, reprehenderit t-bone dolore capicola pastrami bresaola sunt pork belly pariatur. Commodo labore do tenderloin ham hock pig eiusmod. Incididunt duis elit, do brisket mollit filet mignon turducken pork belly ex. Incididunt labore ham hock fatback andouille shankle jowl adipisicing sunt quis nisi. Cow et fugiat beef boudin.",
      "Bresaola hamburger pork loin kevin capicola t-bone. Do id beef pork loin veniam, mollit dolor. Shank ullamco fugiat, turkey pork loin kevin aliqua laboris. Minim meatloaf cupidatat swine, buffalo pancetta short loin esse tail tri-tip aliquip eiusmod sunt ball tip.",
      "Frankfurter turducken pork chop, ribeye alcatra laborum landjaeger boudin ball tip magna sirloin pig anim. Pig ex ut, pork belly adipisicing shankle id cow ipsum fugiat ham hock velit. Lorem laborum sirloin capicola. Boudin dolore voluptate short ribs aute non pork beef. Sed ground round corned beef non ipsum irure mollit biltong.",
      "Pastrami short loin commodo venison, picanha enim ut. Culpa drumstick strip steak laboris, kielbasa buffalo dolor fugiat ullamco brisket ad voluptate chislic. Ad swine burgdoggen short loin alcatra meatball cupidatat do labore venison beef ribs beef pork chop brisket. Ullamco do dolore irure kevin cillum hamburger veniam andouille boudin. Pancetta ad in, cow beef ribs voluptate shankle enim kielbasa est meatloaf officia minim. Kevin eiusmod laboris duis sausage, pig ea shank t-bone. Ex corned beef pork ham hock pastrami, pork belly tempor drumstick fugiat.",
      "Consectetur in capicola adipisicing, bresaola eiusmod hamburger porchetta short ribs esse exercitation. Shank do kevin proident pig ball tip. Dolor alcatra ground round in bacon, irure burgdoggen dolore hamburger elit. Elit jerky cillum dolore nisi tempor sunt ea fugiat aliqua nostrud. Alcatra bresaola commodo, dolore turducken in quis reprehenderit pork. Ex quis corned beef bresaola, enim chicken proident pastrami ut ad.",
    ],
    image: `${PostImage}`,
  },
  {
    title: "where should i start with a remodal?",
    author: "Jake Terpstra",
    date: "Jan 01, 2020",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce ut placerat orci nulla…",
    image: `${PostImage}`,
  },
  {
    title: "where should i start with a remodal?",
    author: "Jake Terpstra",
    date: "Jan 01, 2020",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce ut placerat orci nulla…",
    image: `${PostImage}`,
  },
  {
    title: "where should i start with a remodal?",
    author: "Jake Terpstra",
    date: "Jan 01, 2020",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce ut placerat orci nulla…",
    image: `${PostImage}`,
  },
];
