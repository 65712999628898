import React from "react";
import styled from "styled-components";
//-----COMPONENTS-----
import { Link } from "./globals";
//-----MATERIAL-----
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const Container = styled(Grid).attrs({ item: true })`
  width: 100%;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      width: 50%;
      text-align: right;
      justify-content: flex-end;
    }
  `}
`;

const Image = styled(Box)`
  width: 100%;
  height: 30vh;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      height: 65vh;
    }
  `}
`;

const SwapDirectionGrid = styled(Grid)`
  &:nth-child(odd) {
    flex-direction: row-reverse;
    ${Container} {
      text-align: left;
      justify-content: flex-start;
    }
  }
`;

const PreviewTypography = styled(Typography)`
  ${({ theme }) => `
    ${theme.breakpoints.down("xs")} {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  `}
`;

function BlogTitle(props) {
  return (
    <Grid item component="header">
      <Typography variant="h4" component="h3">
        {props.children}
      </Typography>
    </Grid>
  );
}

function BlogAuthorNDate(props) {
  return (
    <Grid item>
      <Typography paragraph>
        <Box
          component="span"
          fontSize={{ xs: "0.75rem" }}
          fontFamily="montserrat, sans-serif"
          fontWeight="600"
          my={1}
          mb={2}
        >
          {props.children}
        </Box>
      </Typography>
    </Grid>
  );
}

function BlogPreview(props) {
  return (
    <Grid item>
      <PreviewTypography paragraph>{props.children}</PreviewTypography>
    </Grid>
  );
}

function ReadMoreButton(props) {
  return (
    <Grid item>
      <Typography component="span" variant="h5">
        {props.children}
      </Typography>
    </Grid>
  );
}

export default function BlogPost({ post }) {
  const { title, author, date, description, image } = post;
  return (
    <SwapDirectionGrid component="article" container>
      <Container>
        <Link to="/blog">
          <Image image={image} />
        </Link>
      </Container>

      <Container
        container
        alignContent="center"
        component={Box}
        py={3}
        px={{ xs: 3, md: 12 }}
      >
        <BlogTitle>
          <Link to="/blog/">{title}</Link>
        </BlogTitle>

        <BlogAuthorNDate>
          Written by {author}, <time>{date}</time>
        </BlogAuthorNDate>

        <BlogPreview>{description}</BlogPreview>

        <ReadMoreButton>
          <Link to="/blog/">read more</Link>
        </ReadMoreButton>
      </Container>
    </SwapDirectionGrid>
  );
}
